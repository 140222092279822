export default (areaId, callback) => {
  $.ajaxSetup({ cache: false });
  const $includeArea = $(areaId);
  const includeFileName = $includeArea.attr('data-inc');
  const includeFilePath = `/assets/inc/${includeFileName}.html`;

  $.ajax({
    url: includeFilePath,
    type: 'GET',
    dataType: 'html'
  }).then(data => {
    $includeArea.append(data);
  }).then(() => {
    if (callback) callback();
  });
};
