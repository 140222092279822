import 'picturefill';
import objectFitImages from 'object-fit-images';

import CommonUI from './modules/common_ui';
import Loading from './modules/loading';
import include from './modules/include';
import recruit from './modules/recruit';
import RecruitHeader from './modules/recruit-header';
import RecruitFooter from './modules/recruit-footer';
import Header from './modules/header';
import LocalNav from './modules/local-nav';
import Video from './modules/video';

objectFitImages();

// loading
const loading = new Loading();
loading.init();

// Recruit
if ($('.js-recruit-header').length) {
  const recruitHeaderFunctions = () => { new RecruitHeader().init(); };
  include('.js-recruit-header', recruitHeaderFunctions);
  recruit();
}

if ($('.js-recruit-footer').length) {
  const recruitFooterFunctions = () => { new RecruitFooter().init(); };
  include('.js-recruit-footer', recruitFooterFunctions);
}

// Corporate
if ($('.js-header').length) {
  new Header().init();
  new Video().init();
  new CommonUI().smoothScroll('.js-header');
}

if ($('.js-local-nav').length) new LocalNav().init();
