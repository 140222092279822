class LocalNav {
  init () {
    this.setCurrent();
  }

  setCurrent () {
    $('.js-local-nav').find('a').each((index, element) => {
      const href = $(element).attr('href');
      const current = window.location.pathname;
      if (href === current) {
        $(element).parent().remove();
      }
    });
  }
}

export default LocalNav;
