import CommonUI from '../modules/common_ui';

class Header {
  constructor (opt = {}) {
    const common = new CommonUI();
    this.breakPoint = common.breakPointSP;
  }

  init () {
    this.toggleEvents();
    this.scrollEvents();
  }

  toggleEvents () {
    const w = $(window).innerWidth();

    if (w > 960) {
      $('.js-header-accordion-wrap').on('mouseenter', function () {
        $(this).find('.js-recruit-header-accordion').slideDown(400, function (e) {
          $(this).stop(false, true);
        });

        $(this).find('.js-header-accordion-trigger').addClass('is-active');
      });

      $('.js-header-accordion-wrap').on('mouseleave', function () {
        $(this).find('.js-recruit-header-accordion').slideUp(400, function (e) {
          $(this).stop(false, true);
        });

        $(this).find('.js-header-accordion-trigger').removeClass('is-active');
      });
    }
    if (w <= 960) {
      $('.js-header-accordion-trigger').on('click', function () {
        $(this).next().slideToggle();
        $(this).toggleClass('is-active');
        return false;
      });

      $('.js-header-nav-trigger').on('click', function () {
        $('.js-header-nav').slideToggle();
        $(this).toggleClass('is-active');
        $('body').toggleClass('is-open');
        $('.l-header__nav-inner-wrap').toggleClass('is-open');
      });
    }
  }

  scrollEvents () {
    $(window).scroll(function () {
      const removeHeader = $('.js-header');
      const height = $(document).height();
      const position = $(window).height() + $(window).scrollTop();
      const footer = $('footer').height();

      if (height - position < footer) {
        removeHeader.addClass('is-remove');
      } else {
        removeHeader.removeClass('is-remove');
      }
    });
  }
}

export default Header;
