import CommonUI from '../modules/common_ui';

class Loading {
  constructor(opt = {}) {
    const common = new CommonUI();
    this.breakPoint = common.breakPointSP;

    this.$loading = $('.js-loading');
  }

  init() {
    this.bindEvents();
  }

  bindEvents() {
    this.loadingHeight();

    $(window).on('load', () => {
      setTimeout(() => {
        $('body').removeClass('is-loading');
        this.$loading.addClass('is-loaded');
      }, 200);
    });
  }

  loadingHeight() {
    const winW = $(window).innerWidth();
    const winH = $(window).innerHeight();

    if (winW < this.breakPoint) {
      this.$loading.css('height', winH + 'px');
    }
  }
}

export default Loading;
