import CommonUI from '../modules/common_ui';

class RecruitHeader {
  constructor (opt = {}) {
    const common = new CommonUI();
    this.breakPoint = common.breakPointSP;

    this.$header = $('.js-recruit-header');
    this.$headerTrigger = $('.js-recruit-header-trigger');
    this.$accordionTrigger = $('.js-recruit-header-accordion-trigger');
    this.$accordionContent = $('.js-recruit-header-accordion');
  }

  init () {
    this.separate();
    this.bindEvents();
  }

  bindEvents () {

    // fixed header
    $(window).on('load scroll', e => {
      this.fixed(e);
    });

    // navigation
    $(document).on('click', '.js-recruit-header-trigger', () => {
      this.navigationToggle();
    });

    // accordion
    $(document).on('click', '.js-recruit-header-accordion-trigger', e => {
      this.accordion(e);
    });
  }

  separate () {
    const getUrl = location.pathname;
    const recruitTop = ['/recruit/', '/recruit/index.html'];

    if (recruitTop.indexOf(getUrl) >= 0) {
      this.$header.addClass('l-recruit-header--top');
    } else {
      const headerH = this.$header.height();
      $('body').css('padding-top', headerH + 'px');
    }
  }

  fixed (e) {
    const top = $(e.currentTarget).scrollTop();

    if (top > 0) {
      this.$header.addClass('is-scrolling');
    } else {
      this.$header.removeClass('is-scrolling');
    }
  }

  navigationToggle () {
    const w = $(window).innerWidth();
    if (w <= this.breakPoint) {
      this.$header.toggleClass('is-open');
    }
  }

  accordion (e) {
    const w = $(window).innerWidth();
    if (w <= this.breakPoint) {
      const index = this.$accordionTrigger.index(e.currentTarget);
      $(e.currentTarget).toggleClass('is-open');
      this.$accordionContent.eq(index).toggle();
    }
  }
}

export default RecruitHeader;
