import 'slick-carousel';
import CommonUI from '../modules/common_ui';

export default () => {
  const common = new CommonUI();
  common.smoothScroll('.js-recruit-header');

  // slider
  const $slide = $('.js-recruit-top-slide');
  if (!$slide.length) return;

  const zoomItemClassName = '.recruit-top-slide__item-inner';
  $(zoomItemClassName).eq(0).addClass('is-active');

  const $indicator = $('.js-recruit-top-slide-indicator');
  const $counterCurrent = $('.js-recruit-top-slide-current');
  const $counterAll = $('.js-recruit-top-slide-all');
  const slideNum = $slide.children().length;

  $slide.on('init', (event, slick) => {
    $counterAll.text(`0${slideNum}`);
    $indicator.append('<span class="is-init"></span>');
  }).slick({
    arrows: false,
    dots: true,
    appendDots: $('.js-recruit-top-slide-dots'),
    fade: true,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 6000
  });

  const breakPointSp = common.breakPointSP;
  const w = $(window).innerWidth();

  $slide.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    const slideNum = `0${nextSlide + 1}`;
    const $nextSlide = $(`[data-slick-index="${nextSlide}"]`);
    const $currentSlide = $(`[data-slick-index="${currentSlide}"]`);

    $nextSlide.find(zoomItemClassName).addClass('is-active');
    $counterCurrent.text(slideNum);
    $indicator.find('span').remove();
    $indicator.append('<span></span>');

    setTimeout(() => {
      $currentSlide.find(zoomItemClassName).removeClass('is-active');
    }, 3500);

    const color = $nextSlide.find('.recruit-top-slide__item').data('header-color');
    const shadow = $nextSlide.find('.recruit-top-slide__item').data('shadow') === true ? '0 0 5px rgba(0,0,0,0.5), 0 0 10px rgba(0,0,0,0.5)' : 'none';
    if (w > breakPointSp) {
      $('.l-recruit-header__nav-item-link').children('a, span').css({
        'color': color,
        'text-shadow': shadow
      });
    }
  });
};
