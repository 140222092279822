import CommonUI from '../modules/common_ui';

class RecruitFooter {
  constructor (opt = {}) {
    const common = new CommonUI();
    this.breakPoint = common.breakPointSP;
  }

  init () {
    this.bindEvents();
  }

  bindEvents () {
    const footerAccordionTrigger = $('.js-recruit-footer-accordion-trigger');
    const footerAccordion = $('.js-recruit-footer-accordion');
    const w = $(window).innerWidth();

    footerAccordionTrigger.on('click', e => {
      if (w <= this.breakPoint) {
        const index = footerAccordionTrigger.index(e.currentTarget);
        $(e.currentTarget).toggleClass('is-open');
        footerAccordion.eq(index).toggle();
      }
    });
  }
}

export default RecruitFooter;
