class Video {
  init () {
    this.popupVideo();
  }

  popupVideo() {
    $('.modal-trigger').click(function() {
      $(this).next().addClass('is-open');
      $('body').addClass('is-fixed');
      $('.modal-bg').addClass('is-fixed');
    });

    $('.modal-bg, .modal-close').click(function() {
      $('.modal').removeClass('is-open');
      $('body').removeClass('is-fixed');
      $('.modal-bg').removeClass('is-fixed');
      var video = document.getElementById('js-video');
      video.pause();
    });
  }
}

export default Video;
